import React from 'react';

import Layout, { PageHeaderB, PageSection } from '../components/layout';
import { PageText, PageTitle } from '../components/typography';
import { ContactSection } from '../components/contact';
import { JumpBoxLink } from '../components/jumpboxlink';
import { graphql } from 'gatsby';

function SecurityAudits({ data, location }) {
    return (
        <Layout
            location={location}
            bgImage={{
                file: 'code',
                opacity: 0.25,
            }}
            headerContent={
                <PageHeaderB
                    title="Security Audits und Assessments"
                    text={
                        <PageText>
                            <b>Ziel</b>: die Informationssicherheit in
                            Übereinstimmung mit den Geschäftsanforderungen und
                            gesetzlichen Vorschriften zu&nbsp;gewährleisten.
                        </PageText>
                    }
                />
            }
        >
            <PageSection size="big" isDark>
                <PageText>
                    Security Governance bezieht sich auf die Überwachung und
                    Lenkung der Informationssicherheit innerhalb einer
                    Organisation. Es beinhaltet die Festlegung von Richtlinien,
                    Verantwortlichkeiten und Verfahren zur Gewährleistung einer
                    angemessenen Sicherheit für die Informationsverarbeitung und
                    -kommunikation.
                </PageText>
                <PageText>
                    <b>Professional Services</b> werden in folgenden Bereichen
                    angeboten:
                </PageText>
                <ul className="grid sm:grid-cols-3 grid-cols-2 gap-2 max-w-xl">
                    <JumpBoxLink
                        title={'ISM'}
                        desc="Informations-sicherheitsmanagement"
                    />
                    <JumpBoxLink
                        title={'NIS'}
                        desc="Netz- und Informationssystemen"
                    />
                    <JumpBoxLink title={'DPM'} desc="Data Protection" />
                </ul>
            </PageSection>

            <PageSection size="big" id="ISM">
                <PageTitle hasMargin>ISM</PageTitle>
                <PageText>
                    Informationssicherheitsmanagement bezieht sich auf die
                    Gesamtheit aller Aktivitäten, die dazu beitragen, die
                    Integrität, Verfügbarkeit und Vertraulichkeit von
                    Informationen innerhalb einer Organisation zu gewährleisten.
                    Ein wichtiger Rahmen für Informationssicherheitsmanagement
                    ist die ISO 27001, eine international anerkannte Norm, die
                    Anforderungen für ein
                    Informationssicherheitsmanagement-System (ISMS) definiert.
                    Es umfasst die Überprüfung von Informationssystemen,
                    Geschäftsprozessen und Anwendungen auf Sicherheitsrisiken
                    und die Umsetzung von Maßnahmen zur Risikobewältigung. Ziel
                    ist es, ein angemessenes Schutzniveau für wichtige
                    Informationen und Systeme zu gewährleisten und gleichzeitig
                    den Geschäftsbetrieb aufrechtzuerhalten.
                    Informationssicherheitsmanagement umfasst Verfahren wie
                    Risikobewertung, Konfigurationsmanagement, Überwachung und
                    Überprüfung sowie das Management von Sicherheitsincidenten
                    und ist ein wichtiger Bestandteil des Gesamtmanagements
                    einer Organisation. Die Umsetzung der Anforderungen der ISO
                    27001 trägt dazu bei, die Informationssicherheit zu
                    gewährleisten und gleichzeitig den Geschäftsbetrieb zu
                    unterstützen.
                </PageText>
            </PageSection>

            <PageSection size="big" isDark id="NIS">
                <PageTitle hasMargin>NIS</PageTitle>
                <PageText>
                    NIS (Network and Information Systems) und NIS 2 (Network and
                    Information Systems Regulation) sind Regulierungen in
                    Österreich, die sich auf die Informations- und Cybersecurity
                    von kritischen Infrastrukturen beziehen. NIS 2 ist eine
                    Umsetzung der EU-Richtlinie für Netz- und
                    Informationssysteme (NIS) und regelt die Anforderungen für
                    die Informations- und Cybersecurity von Unternehmen, die
                    kritische Infrastrukturen betreiben oder darauf angewiesen
                    sind. Dazu gehören z.B. Energieversorger, Banken,
                    Krankenhäuser und Versorgungsunternehmen. NIS 2 verpflichtet
                    diese Unternehmen zu angemessenen Schutzmaßnahmen und zur
                    Meldung von Sicherheitsvorfällen an die österreichische
                    Regulierungsbehörde. Die Umsetzung von NIS 2 trägt dazu bei,
                    die Informations- und Cybersecurity in Österreich zu
                    verbessern und eine hohe Verfügbarkeit und Integrität
                    kritischer Infrastrukturen sicherzustellen.
                </PageText>
            </PageSection>

            <PageSection size="big" id="DPM">
                <PageTitle hasMargin>Datenschutz</PageTitle>
                <PageText>
                    Datenschutz ist ein wichtiger Bestandteil der
                    Informationssicherheit, der sich auf die Verarbeitung und
                    den Schutz personenbezogener Daten bezieht. Es regelt, wer
                    welche Daten wann, wofür und auf welche Weise verarbeiten
                    darf. Dazu gehören sowohl die Erhebung, Verarbeitung und
                    Übertragung als auch die Speicherung und Löschung
                    personenbezogener Daten. Datenschutz umfasst auch die
                    Informationspflichten gegenüber den betroffenen Personen,
                    wie z.B. die Verpflichtung zur Offenlegung, wie ihre Daten
                    verwendet werden, und das Recht auf Zugang, Berichtigung und
                    Löschung dieser Daten. Zentrale Regelungen zum Datenschutz
                    finden sich in der EU-Datenschutzgrundverordnung (DSGVO) und
                    nationalen Gesetzen. Die Einhaltung datenschutzrechtlicher
                    Vorgaben ist für Unternehmen und Organisationen von großer
                    Bedeutung, um das Vertrauen ihrer Kunden und Nutzer zu
                    gewinnen und sich gegen mögliche datenschutzrechtliche
                    Verstöße abzusichern.
                </PageText>
            </PageSection>

            <ContactSection requestDemo={false} />
        </Layout>
    );
}

export const query = graphql`
    query($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;

export default SecurityAudits;
